import React, { Component } from "react";
import withContext from "../withContext";
import { Redirect } from "react-router-dom";

const initState = {
  username: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: ""
};

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  save = async (e) => {
    e.preventDefault();
    const { username, password, confirmPassword, firstName, lastName, email, phone } = this.state;

    if (username && password && password === confirmPassword) {
      this.props.context.registerUser(
          username,
          password,
          firstName,
          lastName,
          email,
          phone
        ).then(success => {
            if(success){
                this.setState(
                    { flash: { status: 'is-success', msg: 'Registration Successful, You have been sent a verification email, you will need to verify your email before logging in' }}
                  );
            }else{
                this.setState(
                    { flash: { status: 'is-danger', msg: 'Registration Failure' }}
                  );
            }
        });

    } else {
      this.setState(
        { flash: { status: 'is-danger', msg: 'Registration Failure' }}
      );
    }
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value, error: "" });

  render() {
    const { username, password, confirmPassword, firstName, lastName, email, phone } = this.state;
    const { user } = this.props.context;

    return (user) ? (
      <Redirect to="/" />
    ) : (
      <>
        <div className="hero is-primary ">
          <div className="hero-body container">
            <h4 className="title">Register</h4>
          </div>
        </div>
        <br />
        <br />
        <form onSubmit={this.save}>
          <div className="columns is-mobile is-centered">
            <div className="column is-one-third">
              <div className="field">
                <label className="label">First Name: </label>
                <input
                  className="input"
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="field">
                <label className="label">Last Name: </label>
                <input
                  className="input"
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="field">
                <label className="label">Email: </label>
                <input
                  className="input"
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
                <label className="label">Phone Number: </label>
                <input
                  className="input"
                  type="tel"
                  name="phone"
                  value={phone}
                  onChange={this.handleChange}
                />
              </div>
              <div className="field">
              <label className="label">Username: </label>
              <input
                className="input"
                type="text"
                name="username"
                value={username}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="field">
            <label className="label">Password: </label>
            <input
                className="input"
                type="password"
                name="password"
                value={password}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="field">
            <label className="label">Confirm Password: </label>
            <input
                className="input"
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={this.handleChange}
                required
              />
            </div>
              {this.state.flash && (
                <div className={`notification ${this.state.flash.status}`}>
                  {this.state.flash.msg}
                </div>
              )}
              <div className="field is-clearfix">
                <button
                  className="button is-primary is-outlined is-pulled-right"
                  type="submit"
                  onClick={this.save}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default withContext(Register);
