import React, { Component } from "react";
import axios from 'axios';
import LockerItem from "./LockerItem";
import withContext from "../withContext";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state=this.props;
  }

  componentDidMount () {
      //  axios.get('http://chisplay.com:3001/lockers')
      //   .then(lockers => this.setState({lockers: lockers.data}));
    }

render() {
  return (
    <>
      <div className="hero is-small is-primary">
        <div className="hero-body container">
        </div>
      </div>
      <section class="hero is-large has-bg-img"> 
        <div class="hero-body"> 
          <div class="container has-text-centered"> 
            <h1 class="title has-text-white">Find Rental Lockers Near You</h1> 
            <div class="field is-grouped">
              <p class="control is-expanded">
              <input class="input is-rounded" type="text" placeholder="Enter Zip Code"></input>
              </p>
              <p class="control">
                <a href="/lockers" class="button is-rounded is-primary">
                  Search
                </a>
              </p>
            </div>
          </div> 
        </div> 
      </section>
    </>
  );
}
}

export default withContext(Home);
