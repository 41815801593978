import React, { Component } from "react";
import { Switch, Route, Link, BrowserRouter as Router } from "react-router-dom";
import axios from 'axios';
import jwt_decode from 'jwt-decode';

import Register from './components/Register';
import Cart from './components/Cart';
import Login from './components/Login';
import ProductList from './components/ProductList';
import LockerList from './components/LockerList';

import Context from "./Context";
import Home from "./components/Home";

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
const CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;
const AWS = require('aws-sdk');
const request = require('request');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');
global.fetch = require('node-fetch');

const poolData = {
UserPoolId : "us-east-1_x63WomYi1", // Your user pool id here
ClientId : "5g2cara6407ot8a77lis4iij7m" // Your client id here
};
const pool_region = 'us-east-1';

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      cart: {},
      products: []
    };
    this.routerRef = React.createRef();
  }

  async componentDidMount() {
    let user = localStorage.getItem("user");
    let cart = localStorage.getItem("cart");

    const lockers = await axios.get('https://chisplapi.chisplay.com/lockers');
    user = user ? JSON.parse(user) : null;
    cart = cart? JSON.parse(cart) : {};

    this.setState({ user,  lockers: lockers.data, cart });
  }

  async authenticateUser(cognitoUser, cognitoAuthenticationDetails) {
    return new Promise(function(resolve, reject) {
      cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
        onSuccess: resolve,
        onFailure: reject,
        newPasswordRequired: resolve
      })
    })
  }

  login = async (username, password) => {

    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username : username,
      Password : password,
    });

    var userData = {
      Username : username,
      Pool : userPool
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    try {
      let result =
        await this.authenticateUser(cognitoUser, authenticationDetails)

      if ('idToken' in result) {
        var user = {
          email: result.getIdToken().payload.email,
          access_token: result.getAccessToken().getJwtToken(),
          id_token: result.getIdToken().getJwtToken(),
          refresh_token: result.getRefreshToken().getToken(),
          accessLevel: 0
        }
        this.setState({ user });
        localStorage.setItem("user", JSON.stringify(user));
        return true;
      }
      else {
        console.log('We need a new password.')
        delete result.email_verified // Not accepted by the challenge call
        delete result.phone_number_verified // Also not accepted

        // Get a new password from the user then call
        // cognitoUser.completeNewPasswordChallenge()
      }
      }catch (error) {
        // Probably a mis-typed password
        console.log(error.message)
      }
      return false;
  }

  logout = e => {
    e.preventDefault();
    this.setState({ user: null });
    localStorage.removeItem("user");
  };

  async createUser(username, password, attributeList) {
      return new Promise(function(resolve, reject) {
        userPool.signUp(username, password, attributeList, null, (err, result) => {
              if (err) {
                console.log(err.message);
                reject(err);
                return;
              }
              let cognitoUser = result.user;
              resolve(cognitoUser)
            });
      })
    }

  registerUser = async (username, password, firstName, lastName, email, phone) => {
      var attributeList = [];
      attributeList.push(new AmazonCognitoIdentity.CognitoUserAttribute({Name:"name",Value:firstName}));
      attributeList.push(new AmazonCognitoIdentity.CognitoUserAttribute({Name:"family_name",Value:lastName}));
      attributeList.push(new AmazonCognitoIdentity.CognitoUserAttribute({Name:"email",Value:email}));
      attributeList.push(new AmazonCognitoIdentity.CognitoUserAttribute({Name:"phone_number",Value:"+1"+phone}));

      try{
        var result =
          await this.createUser(username, password, attributeList);
        //Right now just returning true or false but will want to do something with the user or error eventually
        return true;
      } catch(error) {
        return false;
      }
  }

  addToCart = cartItem => {
    let cart = this.state.cart;
    if (cart[cartItem.id]) {
      cart[cartItem.id].amount += cartItem.amount;
    } else {
      cart[cartItem.id] = cartItem;
    }
    if (cart[cartItem.id].amount > cart[cartItem.id].product.stock) {
      cart[cartItem.id].amount = cart[cartItem.id].product.stock;
    }
    localStorage.setItem("cart", JSON.stringify(cart));
    this.setState({ cart });
  };

  removeFromCart = cartItemId => {
    let cart = this.state.cart;
    delete cart[cartItemId];
    localStorage.setItem("cart", JSON.stringify(cart));
    this.setState({ cart });
  };

  clearCart = () => {
    let cart = {};
    localStorage.removeItem("cart");
    this.setState({ cart });
  };

  checkout = () => {
    if (!this.state.user) {
      this.routerRef.current.history.push("/login");
      return;
    }

    const cart = this.state.cart;

    const products = this.state.products.map(p => {
      if (cart[p.name]) {
        p.stock = p.stock - cart[p.name].amount;

        axios.put(
          `http://localhost:3001/products/${p.id}`,
          { ...p },
        )
      }
      return p;
    });

    this.setState({ products });
    this.clearCart();
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          removeFromCart: this.removeFromCart,
          addToCart: this.addToCart,
          login: this.login,
          addProduct: this.addProduct,
          clearCart: this.clearCart,
          checkout: this.checkout,
          registerUser: this.registerUser,
          getProductsForLocker: this.getProductsForLocker
        }}
      >
        <Router ref={this.routerRef}>
        <div className="App">
          <nav
            className="navbar container"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="navbar-brand">
              <a class="navbar-item" href="https://chisplay.com">
                <img src="/logo.png" height="100"></img>
              </a>
              <label
                role="button"
                class="navbar-burger burger"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ showMenu: !this.state.showMenu });
                }}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </label>
            </div>
              <div className={`navbar-menu ${
                  this.state.showMenu ? "is-active" : ""
                }`}>
                <Link to="/" className="navbar-item">
                  Home
                </Link>
                <Link to="/lockers" className="navbar-item">
                  Lockers
                </Link>
                <Link to="/cart" className="navbar-item">
                  Cart
                  <span
                    className="tag is-primary"
                    style={{ marginLeft: "5px" }}
                  >
                    { Object.keys(this.state.cart).length }
                  </span>
                </Link>
                {!this.state.user ? (
                  <Link to="/login" className="navbar-item">
                    Login
                  </Link>
                ) : (
                  <Link to="/" onClick={this.logout} className="navbar-item">
                    Logout
                  </Link>
                )}
                {!this.state.user && (
                  <Link to="/register" className="navbar-item">
                    Register
                  </Link>
                )}
              </div>
            </nav>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/cart" component={Cart} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/lockers/:lockerId" component={ProductList} />
              <Route exact path="/lockers" component={LockerList} />
            </Switch>
          </div>
          <footer class="footer">
          <div class="container">
            <div class="content has-text-centered">
              <p>
                Copyright Chisp Technologies LLC 2021
              </p>
            </div>
          </div>
        </footer>
        </Router>
      </Context.Provider>
    );
  }
}
