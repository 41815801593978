import React, { Component } from "react";
import axios from 'axios';
import ProductItem from "./ProductItem";
import withContext from "../withContext";

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state=this.props;
  }

  componentDidMount () {
       axios.get('https://chisplapi.chisplay.com/lockers/'+this.props.match.params.lockerId)
        .then(products => this.setState({products: products.data}));
    }

  render() {
  return (
    <>
      <div className="hero is-small is-primary">
        <div className="hero-body container">
        </div>
      </div>
      <br />
      <div className="container">
        <div className="column columns is-multiline">
          {this.state.products && this.state.products.length ? (
            this.state.products.map((product, index) => (
              <ProductItem
                product={product}
                key={index}
                addToCart={this.state.context.addToCart}
              />
            ))
          ) : (
            <div className="column">
              <span className="title has-text-grey-light">
                No products currently available
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
}

export default withContext(ProductList);
