import React from "react";
import { Link } from "react-router-dom";

const LockerItem = props => {
  const { locker } = props;
  return (
  <Link to={locker.available? ("/lockers/"+locker.id) : ""} className="column is-half">
      <div className="box">
        <div className="media">
          <div className="media-left">
            <figure className="image is-64x64">
              <img
                src="https://bulma.io/images/placeholders/128x128.png"
                alt={locker.shortDesc}
              />
            </figure>
          </div>
          <div className="media-content">
            <b style={{ textTransform: "capitalize" }}>
              {locker.name}{" "}
            </b>
            <div>{locker.shortDesc}</div>
            {locker.available === false && (
              <small className="has-text-danger">Currently Unavailable</small>
            )}
          </div>
        </div>
      </div>
  </Link>
  );
};

export default LockerItem;
