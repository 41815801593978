import React, { Component } from "react";
import axios from 'axios';
import LockerItem from "./LockerItem";
import withContext from "../withContext";

class LockerList extends Component {
  constructor(props) {
    super(props);
    this.state=this.props;
  }

  componentDidMount () {
       axios.get('https://chisplapi.chisplay.com/lockers')
        .then(lockers => this.setState({lockers: lockers.data}));
    }

render() {
  return (
    <>
      <div className="hero is-small is-primary">
        <div className="hero-body container">
        </div>
      </div>
      <br />
      <div className="container">
        <div className="column columns is-multiline">
          {this.state.lockers && this.state.lockers.length ? (
            this.state.lockers.map((locker, index) => (
              <LockerItem
                locker={locker}
                key={index}
              />
            ))
          ) : (
            <div className="column">
              <span className="title has-text-grey-light">
                No lockers currently available
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
}

export default withContext(LockerList);
